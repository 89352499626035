<div *ngIf="showPageLoader">
    <div class="l-logo-txt-wrap">
        <div class="l-logo-loader-wrap">
            <ng-lottie  [options]="options"  [speed]="0.4" (animationCreated)="animationCreated($event)" width="100px" height="100px"></ng-lottie>
             <!-- <img class="l-logo-loader-img" src="../.././../assets/images/Catalyst-Preloader.gif" /> -->
            <!-- <span class="l-last-lineAni l-last-line-one"></span>
            <span class="l-last-lineAni l-last-line-two"></span>
    
            <span class="l-side-lineAni l-side-line-one"></span>
            <span class="l-side-lineAni l-side-line-two"></span>
            <span class="l-side-lineAni l-side-line-three"></span>
            <span class="l-side-lineAni l-side-line-four"></span>
            <span class="l-side-lineAni l-side-line-five"></span>
    
            <span class="l-ani-dot l-dot-one"> </span>
            <span class="l-ani-dot l-dot-two"> </span>
            <span class="l-ani-dot l-dot-three"> </span>
            <span class="l-ani-dot l-dot-four"> </span>
            <span class="l-ani-dot l-dot-five"> </span>
            <span class="l-ani-dot l-dot-six"> </span>
            <span class="l-ani-dot l-dot-seven"> </span>
    
            <span class="l-lineAni l-line-one"></span>
            <span class="l-lineAni l-line-two"></span>
            <span class="l-lineAni l-line-three"></span>
            <span class="l-lineAni l-line-four"></span>
    
            <div class="l-center-circle"> </div> -->
        </div>
        <!-- <div class="l-loading-txt">Loading <span>.</span><span>.</span><span>.</span></div> -->
    </div>
</div>
<router-outlet></router-outlet>
