import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class RouteTestGuard implements CanActivate {

    constructor(private injector: Injector,
    ) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        // let moduleName = (this.injector.get('NgModuleRef') as any);
        // console.log(`Module Name: ${moduleName}`);
        // console.log(moduleName.module.name);
        return true;
    }
}