import { Component } from '@angular/core';
import { BootService } from './boot.service';
//lottie
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: 'app-boot',
  templateUrl: './boot.component.html',
  styleUrls: ['./boot.component.css']
})
export class BootComponent {
  title = 'Catalyst Base Framework';

  public showPageLoader:Boolean = true;
  options: AnimationOptions = {
    path: '../.././../assets/lottie/Catalyst-LogoV3.json',
  };
  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  constructor(private bootService: BootService) {

    this.bootService.getFullPageLoaderStatus().subscribe(
      status => this.showPageLoader = status
    );
  }

}
