import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable ,  Subject } from 'rxjs';



@Injectable()
export class BootService {

    public displayFullPageLoader = new Subject<any>();
    constructor() {}
    
    setFullPageLoaderStatus(value: boolean) {
        this.displayFullPageLoader.next(value);
    }
 
    getFullPageLoaderStatus(): Observable<any> {
        return this.displayFullPageLoader.asObservable();
    }
}
