import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// import { Angulartics2Module } from 'angulartics2';
// import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Angulartics2Module } from 'angulartics2';
// import {Angulartics2GoogleAnalytics} from 'angulartics2/ga'
import { ToastrModule } from 'ngx-toastr';
import { BootComponent } from './pages/boot/boot.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootService } from './pages/boot/boot.service';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouteTestGuard } from 'modules/catalyst/app/shared/guards/routeTest.guard';

//Lottie
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

// const appRoutes: Routes = environment.lazyLoadedModules;
const appRoutes: Routes = [
  {
    path: '', loadChildren: () => import('../modules/catalyst/app/catalyst.module').then(
      m => m.CatalystModule),
    canActivate: [RouteTestGuard]
  },
  {
    path: 'content-manager', loadChildren: () => import('../modules/catalyst/app/content-manager/content-manager.module').then(
      m => m.ContentManagerModule),
    canActivate: [RouteTestGuard]
  },
  {
    path: '',
    redirectTo: '', 
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '' 
  }
];
@NgModule({
  declarations: [
    BootComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
    CommonModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right' }),
    RouterModule.forRoot(appRoutes),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [BootService, RouteTestGuard], // { provide: LocationStrategy, useClass: HashLocationStrategy }
  bootstrap: [BootComponent]
})

export class AppModule {
}
